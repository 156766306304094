export function parseBoolean(val) {
  try {
    return JSON.parse(val);
  } catch {
    return null;
  }
}

export const removeDashBracket = (str) => {
  if (typeof str !== 'string') return str;
  return str.replace(/[-()\s]+/g, ' ').trim();
};
